import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    fontSize: 12,
    fontStyle: 'bold',
    color: '#444',
    marginTop: '30px',
    paddingTop: '10px',
    position: 'absolute',
    bottom: 60,
    right: 40,
    left: 40
  },
  reportTitle: {
    fontSize: 10,
    color: '#888',
    width: '100%',
  },
  reportNote: {
    fontSize: 10,
    color: '#000',
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
  },
  siret: {
    backgroundColor: color,
    width: '100%',
    fontSize: 10,
    textAlign: 'center',
    marginTop: '10px',
    padding: '3px',
    lineHeight: 0,
  },
  box: { width: '100%', marginBottom: 30, borderRadius: 5 },
});


const InvoiceThankYouMsg = ({ row }) => (
  <View style={styles.row}>
    <View style={[styles.box, { height: 5, backgroundColor: color }]} />
    {!row.hideBankData && (
      <>
        <Text style={styles.reportTitle}>{`BANQUE: ${row.bank || JSON.parse(localStorage.cltObj || '{}').bank}`}</Text>
        <Text style={styles.reportTitle}>{`IBAN: ${row.iban || JSON.parse(localStorage.cltObj || '{}').iban}`}</Text>
        <Text style={styles.reportTitle}>{`CODE BIC: ${row.bic || JSON.parse(localStorage.cltObj || '{}').bic}`}</Text>
      </>
    )}
    <Text style={styles.reportNote}>{JSON.parse(localStorage.cltObj || '{}').note}</Text>
    <Text style={styles.siret}>{`SIRET: ${JSON.parse(localStorage.cltObj || '{}').siret}`}</Text>
  </View >
);

export default InvoiceThankYouMsg