import React, { useEffect, useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { apiUrl, domainUrl } from '../vars';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from "react-router-dom";
import EmailIcon from '@material-ui/icons/Email';
import { injectIntl } from 'react-intl';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  // Get day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Add leading zeros if necessary
  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;

  // Format the date in dd/mm/yyyy format
  const europeanDate = `${formattedDay}/${formattedMonth}/${year}`;

  return europeanDate;
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

const Alerts = (props) => {
  const [alertsPayments, setAlertsPayments] = useState([]);
  const [loadingAlertsPayments, setLoadingAlertsPayments] = useState(true);

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);

  const today = new Date()

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}_alerts?token=${localStorage.token}`,
      {
        filter: {
          date: {
            $lte: today.getTime()
          },
          readed: 0,
          type: 'maintenance'
        },
      })
      .then(function (response) {
        setAlerts(response.data.entries)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.post(`${apiUrl}/collections/get/${localStorage.client}_alerts?token=${localStorage.token}`,
      {
        filter: {
          date: {
            $lte: today.getTime()
          },
          readed: 0,
          type: 'paiement manquant'
        },
      })
      .then((response) => {
        const { entries } = response.data;
        setAlertsPayments(entries);
        setLoadingAlertsPayments(false);
      })
      .catch(function (error) {
        // localStorage.clear();
        console.log(error);
      });
  }, [])

  const classes = useStyles();

  console.log(alerts)

  const handleMarkAsRead = alert => {
    const data = {
      ...alert,
      readed: 1
    };

    axios.post(`${apiUrl}/collections/save/${localStorage.client}_alerts?token=${localStorage.token}`, { data })
      .then(function () {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleSendInvoice = alert => {

    axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`, { filter: { _id: alert.invoiceId } })
      .then((response) => {
        const invoice = response.data.entries;
        const urlSendEmail = `https://www.joelcalheiros.com/contact.php`;

        const formDataEmail = new FormData();
        formDataEmail.append('template_id', "d-a13f7f88524a4d1abc3af1902b6da488");
        formDataEmail.append('file', `${domainUrl}/upload/${JSON.parse(localStorage.cltObj || '{}').client}/invoices/${invoice[0].invoice}.pdf`);
        formDataEmail.append('file_name', `${invoice[0].invoice}.pdf`);
        formDataEmail.append('mail_to', invoice[0].email);
        formDataEmail.append('invoice_no', invoice[0].invoice);
        formDataEmail.append('client', JSON.parse(localStorage.cltObj || '{}').company);
        formDataEmail.append('client_email', JSON.parse(localStorage.cltObj || '{}').email);
        formDataEmail.append('imageLogo', `${domainUrl}${JSON.parse(localStorage.cltObj || '{}').logo.path}`);
        formDataEmail.append('total', props.intl.formatNumber(invoice[0].total, { style: 'currency', currency: 'EUR' }));
        formDataEmail.append('date', props.intl.formatDate(invoice[0].date));
        formDataEmail.append('clientName', invoice[0].clientName);

        if (!invoice?.[0].hideBankData) {
          formDataEmail.append('bank', invoice?.[0].bank || JSON.parse(localStorage.cltObj || '{}').bank);
          formDataEmail.append('bic', invoice?.[0].bic || JSON.parse(localStorage.cltObj || '{}').bic);
          formDataEmail.append('iban', invoice?.[0].iban || JSON.parse(localStorage.cltObj || '{}').iban);
        }

        axios.post(urlSendEmail, formDataEmail).then((resp) => {
          console.log('resp', resp)
          NotificationManager.success('La facture a été envoyée.', 'Succès');

          const data = {
            ...alert,
            readed: 1
          };

          axios.post(`${apiUrl}/collections/save/${localStorage.client}_alerts?token=${localStorage.token}`, { data })
            .then(function () {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      })
      .catch(function (error) {
        // localStorage.clear();
        console.log(error);
      });

    handleMarkAsRead(alert)
  }

  return (
    <div style={{ width: '100%', padding: 25 }}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Alertes de paiement
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell><b>Client name</b></TableCell>
              <TableCell><b>Nom société</b></TableCell>
              <TableCell><b>Type</b></TableCell>
              <TableCell><b>Email</b></TableCell>
              <TableCell><b>Phone</b></TableCell>
              <TableCell><b>Facture</b></TableCell>
              <TableCell><b>Date</b></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertsPayments.flatMap(row => ({ ...row, id: row._id, date: formatDate(row.date) }))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/invoices/detail/${row.invoiceId}`}>{row.clientName}</Link>
                  </TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.clientCompany}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.type}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.email}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.phone}</Link></TableCell>
                  <TableCell>
                    <Link to={`/invoices/detail/${row.invoiceId}`}>
                      {row.invoice}
                    </Link>
                  </TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.date}</Link></TableCell>
                  <TableCell>
                    <Tooltip title="Renvoyer la facture" placement="top">
                      <IconButton variant="contained" onClick={() => handleSendInvoice(row)}>
                        <EmailIcon />
                      </IconButton >
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ height: 35 }} />
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Alertes de maintenance
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell><b>Client name</b></TableCell>
              <TableCell><b>Nom société</b></TableCell>
              <TableCell><b>Type</b></TableCell>
              <TableCell><b>Email</b></TableCell>
              <TableCell><b>Phone</b></TableCell>
              <TableCell><b>Facture</b></TableCell>
              <TableCell><b>Date</b></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.flatMap(row => ({ ...row, id: row._id, date: formatDate(row.date) }))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/invoices/detail/${row.invoiceId}`}>{row.clientName}</Link>
                  </TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.clientCompany}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.type}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.email}</Link></TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.phone}</Link></TableCell>
                  <TableCell>
                    <Link to={`/invoices/detail/${row.invoiceId}`}>
                      {row.invoice}
                    </Link>
                  </TableCell>
                  <TableCell><Link to={`/invoices/detail/${row.invoiceId}`}>{row.date}</Link></TableCell>
                  <TableCell>
                    <Tooltip title="Supprimer l'alerte" placement="top">
                      <IconButton variant="contained" onClick={() => handleMarkAsRead(row)}>
                        <DeleteForeverIcon />
                      </IconButton >
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && alerts.length === 0 && !loadingAlertsPayments && alertsPayments.length === 0 && (
        <div style={{ fontSize: '32px', padding: '150px', textAlign: 'center' }}>Il n'y a aucune alerte à afficher</div>
      )}
    </div>
  );
}

export default injectIntl(Alerts);